import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { SinkerTube } from 'models/ExtraEquipment';
import { SinkerTubeService } from 'services';
import { ErrorService } from 'services/error-service';

@autoinject
export class SinkertubeList {
  protected newButtonText = this.i18n.tr('general.createNew', {
    replace: {
      value: this.i18n.tr('general.sinker').toLowerCase(),
    },
  });

  protected sinkerTubes: Array<SinkerTube>;
  protected activeTableRow: number;

  constructor(
    private sinkertubesService: SinkerTubeService,
    private errorService: ErrorService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {}

  protected activate(params: { Id: number }) {
    this.activeTableRow = params?.Id || 0;
  }

  protected attached() {
    this.eventAggregator.subscribe('sinkertube-list-reset', () => {
      this.activeTableRow = null;
      void this.getSinkertubes();
    });
    void this.getSinkertubes();
  }

  private async getSinkertubes() {
    try {
      this.sinkerTubes = await this.sinkertubesService.getAll('?$orderby=Title');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected gotoDetailView(id: number) {
    this.activeTableRow = id;
    this.router.navigateToRoute('sinkertube-detail', { Id: id });
  }
}
