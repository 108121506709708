import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SinkerTubeService } from 'services';
import { SinkerTube } from 'models/ExtraEquipment';

@autoinject
export class SinkerTubeDetail {
  private sinkertube: SinkerTube;

  constructor(
    private sinkertubeService: SinkerTubeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  protected activate(params: { Id: number }) {
    void this.getSinkertube(params.Id);
  }

  protected async getSinkertube(id: number) {
    try {
      this.sinkertube = await this.sinkertubeService.get(id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async updateSinkertube() {
    try {
      this.sinkertube = await this.sinkertubeService.put(this.sinkertube, this.sinkertube.Id);
      this.eventAggregator.publish('sinkertube-list-reset', 1);
      this.toastService.showSuccess('general.saved');
      this.router.navigateToRoute('sinkertube-list');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected deleteSinkertube() {
    void this.deleteDialogService.confirmBeforeDelete(async () => {
      try {
        this.sinkertube = await this.sinkertubeService.delete(this.sinkertube.Id);
        this.eventAggregator.publish('sinkertube-list-reset', 1);
        this.toastService.showSuccess('sinker.deleted');
        this.router.navigateToRoute('sinkertube-list');
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }

  gotoParentView() {
    this.eventAggregator.publish('sinkertube-list-reset', 1);
    this.router.navigateToRoute('sinkertube-list');
  }
}
