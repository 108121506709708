import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SinkerTube } from 'models/ExtraEquipment';
import { SinkerTubeService } from 'services';

@autoinject
export class SinkertubeNew {
  private sinkertube: SinkerTube = new SinkerTube();

  constructor(
    private sinkertubeService: SinkerTubeService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  protected async createSinkertube() {
    try {
      const sinkertube = await this.sinkertubeService.post(this.sinkertube);
      this.toastService.showSuccess('general.created');
      this.eventAggregator.publish('sinkertube-list-reset', 1);
      this.router.navigateToRoute('sinkertube-detail', { Id: sinkertube.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
