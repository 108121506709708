import { autoinject, bindable } from 'aurelia-framework';
import { ExtraEquipmentType, ExtraEquipmentTypes, SinkerTube } from 'models/ExtraEquipment';
import { ExtraEquipmentTypeService } from 'services';

const validTypes: ExtraEquipmentTypes[] = [
  ExtraEquipmentTypes.BottomRing,
  ExtraEquipmentTypes.CenterSinker,
  ExtraEquipmentTypes.SingleSinker,
];

@autoinject
export class SinkertubeForm {
  @bindable() protected sinkertube: SinkerTube;
  private extraEquipmentTypes: Array<ExtraEquipmentType>;

  constructor(protected extraEquipmentTypesService: ExtraEquipmentTypeService) {
    //
  }

  protected async getExtraEquipmentTypes() {
    const extraEquipmentTypes = await this.extraEquipmentTypesService.getAll('?$orderby=Title');
    this.extraEquipmentTypes = extraEquipmentTypes.filter((type) => validTypes.includes(type.Type));
    return this.extraEquipmentTypes;
  }

  protected setExtraEquipmenType({ detail }: { detail: { value: string; values: Array<ExtraEquipmentType> } }) {
    this.sinkertube.TypeId = +detail.value;
  }
}
